import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { Container, ContainerTypeId } from '@flow/flow-backend-types';
import { ValidationFilter } from 'stores/filters';
import { exists } from 'utils';
import { isEventReported, getIsVisible } from 'stores/uiEvent';
import { getReportCollectionKey, pullLastReport, getLastReportedValues } from 'stores/report/report.utils';
import {
  ChildrenToParentMap,
  ContainersMap,
  ContainerDoesMatchFiltersParams,
  CountFilteredRelatedContainersParams,
  IsEventValidAccordingToFiltersParams,
  IsEventMatchingValueFiltersParams,
  IsEventMatchingGlobalFiltersParams,
} from './container.types';

export function childToParentMap(containersMap: ContainersMap): ChildrenToParentMap {
  return Object.keys(containersMap).reduce((childToParent, childId) => {
    const parent = Object.values(containersMap).find(({ childrenIds }) => childrenIds?.includes(childId));
    childToParent[childId] = parent?.id;
    return childToParent;
  }, {} as ChildrenToParentMap);
}

export function getAllParentsRecursively(childToParent: ChildrenToParentMap, childId: string): string[] {
  const parentId = childToParent[childId];
  if (parentId) {
    return [parentId, ...getAllParentsRecursively(childToParent, parentId)];
  }
  return [];
}

export function isEventMatchingValueFilters({
  values,
  valueFilters,
  isContainerNotApplicable,
}: IsEventMatchingValueFiltersParams): boolean {
  if (isContainerNotApplicable) {
    return valueFilters.includes(ApplicabilityReportValue.NOT_APPLICABLE);
  }

  if (!values.length) {
    return valueFilters.includes(ApplicabilityReportValue.APPLICABLE);
  }

  return valueFilters.some((filter) => values.includes(filter));
}

export function isEventMatchingGlobalFilters({
  globalFilters,
  uiEvent,
  missingMandatory,
  bounded,
}: IsEventMatchingGlobalFiltersParams): boolean {
  if (!uiEvent) return false;
  if (globalFilters.outOfBounds) return bounded === false;
  if (globalFilters.missingMandatory) return missingMandatory === true;
  return true;
}

export function isEventValidAccordingToFilters({
  values,
  validationFilters,
  valid,
  isVisible,
}: IsEventValidAccordingToFiltersParams): boolean {
  return validationFilters.some((validationFilter) => {
    switch (validationFilter) {
      case ValidationFilter.Valid:
        if (!values.length) return false;
        return valid === true;
      case ValidationFilter.Invalid:
        if (!values.length) return false;
        return valid === false;
      case ValidationFilter.Unchecked:
        return !values.length && isVisible;
      case ValidationFilter.Checked:
        return !!values.length;
      default:
        return false;
    }
  });
}

export function containerDoesMatchFilters({
  container,
  uiEvents,
  filterValues,
  ceBridgeMap = {},
  globalFilters,
  reports,
  validity,
  boundedness,
  visibilityBindings,
}: ContainerDoesMatchFiltersParams): boolean {
  const applicabilityEventId = Object.values(ceBridgeMap).find(
    ({ uiEventId }) => uiEvents[uiEventId]?.type === 'ApplicabilityEvent',
  )?.uiEventId;
  const isContainerNotApplicable =
    pullLastReport(reports, container.id, applicabilityEventId ?? '')?.reportedValue ===
    ApplicabilityReportValue.NOT_APPLICABLE;
  // if no filters, this is true by default
  const matchedByValueFilters = filterValues.every(({ uiEventId, valueFilters, validationFilters }) => {
    // Check if the uiEvent is associated with the container
    if (!ceBridgeMap || !ceBridgeMap[uiEventId]) return false;

    if (!valueFilters.length && !validationFilters.length) return true;
    const values = getLastReportedValues(container.id, uiEvents[uiEventId], reports);
    const bridge = ceBridgeMap[uiEventId];
    const isVisible = getIsVisible({
      bridge,
      containerId: container.id,
      uiEvents,
      reports,
      visibilityBindings,
      validity,
    });

    const eventMatchingValueFilters = isEventMatchingValueFilters({
      values,
      valueFilters,
      isContainerNotApplicable,
    });

    const eventMatchingValidationFilters = isEventValidAccordingToFilters({
      values,
      validationFilters,
      valid: validity[getReportCollectionKey(container.id, uiEventId)],
      isVisible,
    });

    return eventMatchingValueFilters || eventMatchingValidationFilters;
  });

  const matchedByGlobalFilters = Object.values(ceBridgeMap).some((ceBridge) => {
    const uiEvent = uiEvents[ceBridge.uiEventId];
    const isVisible = getIsVisible({
      bridge: ceBridge,
      containerId: container.id,
      uiEvents,
      reports,
      visibilityBindings,
      validity,
    });
    const missingMandatory = ceBridge.isMandatory && isVisible && !isEventReported(container.id, uiEvent, reports);
    const key = getReportCollectionKey(container.id, uiEvent.id);
    const bounded = boundedness[key];

    return isEventMatchingGlobalFilters({ uiEvent, globalFilters, missingMandatory, bounded });
  });

  return matchedByValueFilters && matchedByGlobalFilters;
}

export function countFilteredRelatedContainers({
  containers,
  container,
  uiEvents,
  containerEventsMap,
  containerTemplatesMap,
  filterValues,
  globalFilters,
  reports,
  validity,
  boundedness,
  visibilityBindings,
}: CountFilteredRelatedContainersParams): number {
  if (container.childrenIds?.length) {
    return container.childrenIds.reduce((acc, childId) => {
      const childContainer = containers[childId];
      if (exists(childContainer)) {
        return (
          acc +
          countFilteredRelatedContainers({
            containers,
            container: childContainer,
            filterValues,
            globalFilters,
            uiEvents,
            containerEventsMap,
            containerTemplatesMap,
            reports,
            validity,
            boundedness,
            visibilityBindings,
          })
        );
      }
      return acc;
    }, 0);
  }

  return containerDoesMatchFilters({
    container,
    uiEvents,
    filterValues,
    ceBridgeMap: container.isDynamic
      ? containerTemplatesMap[container.containerTypeId]
      : containerEventsMap[container.id],
    globalFilters,
    reports,
    validity,
    boundedness,
    visibilityBindings,
  })
    ? 1
    : 0;
}

export function getDynamicContainersCountByTemplateId(
  containers: Container[],
  containerTypeId: ContainerTypeId,
): number {
  return containers?.filter(({ isDynamic, containerTypeId: tId }) => !!isDynamic && containerTypeId === tId).length;
}

/** Sort container array by `order` in place. */
export function orderContainers(containers: Container[]) {
  containers.sort((a, b) => a.order - b.order);
  return containers;
}

/** Recursively DFS for leaf containers. Return them all sorted. */
export function filterLeafContainers(
  containers: ContainersMap,
  containerId: string,
  array: Container[] = [],
): Container[] {
  const container = containers[containerId];

  if (!container) return array;
  const isLeafContainer = !container.childrenIds?.length;
  if (isLeafContainer) {
    array.push(container);
    return array;
  }
  const childContainers = container.childrenIds?.map((childId) => containers[childId]) ?? [];
  orderContainers(childContainers);
  childContainers.forEach((child) => filterLeafContainers(containers, child.id, array));
  return array;
}
