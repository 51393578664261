/*
  There are 3 ways to configure the application with env vars (in order of priority):
  1. using window vars - production
  2. using vite env vars from .env file - development with proxy server against the cloud
  3. default value - development with docker-compose
*/

export const config = {
  apiHost: window.AIOLA_CLIENT_API_HOST ?? import.meta.env.VITE_API_HOST ?? 'http://localhost:8080',
  wsHost: window.AIOLA_CLIENT_WS_HOST ?? import.meta.env.VITE_WS_HOST ?? 'ws://localhost:4001',
  authHost: window.AIOLA_CLIENT_AUTH_HOST ?? import.meta.env.VITE_AUTH_HOST ?? 'http://localhost:8080',
  audioWsHost: window.AIOLA_CLIENT_AUDIO_WS_HOST ?? import.meta.env.VITE_AUDIO_WS_HOST ?? 'wss://flow-app.ai0la.com',
  apiPath: window.AIOLA_CLIENT_API_PATH ?? '/app/api',
  flowApiPrefix: window.AIOLA_CLIENT_FLOW_API_PREFIX ?? 'backend',
  audioPath: window.AIOLA_CLIENT_AUDIO_PATH ?? '/audio/io',
  authPath: window.AIOLA_CLIENT_AUTH_PATH ?? '/app/api',
  customerId: window.AIOLA_CLIENT_CUSTOMER_ID ?? 'aiola',
  appVersion: import.meta.env.PACKAGE_VERSION,
  appVersionTime: __APP_VERSION_TIME__,
  pingInterval: window.AIOLA_CLIENT_PING_INTERVAL ?? 5000,
  periodicSyncInterval: window.AIOLA_CLIENT_PERIODIC_SYNC_INTERVAL ?? 300000,
  biEventsSyncInterval: window.AIOLA_CLIENT_BI_EVENTS_SYNC_INTERVAL ?? 5000,
  pendingSyncInterval: window.AIOLA_CLIENT_PENDING_SYNC_INTERVAL ?? 2000,
  successPingCountThreshold: window.AIOLA_CLIENT_SUCCESS_PING_COUNT_THRESHOLD ?? 2,
  failurePingCountThreshold: window.AIOLA_CLIENT_FAILURE_PING_COUNT_THRESHOLD ?? 2,
  offlineTimeout: window.AIOLA_CLIENT_OFFLINE_TIMEOUT ?? 1500,
  enableFocusAnimations: window.AIOLA_CLIENT_ENABLE_FOCUS_ANIMATIONS ?? true,
  disclaimerLogoUrl: window.AIOLA_CLIENT_DISCLAIMER_LOGO_URL ?? '',
  disclaimerText: window.AIOLA_CLIENT_DISCLAIMER_TEXT ?? '',
  clientLogoUrl: window.AIOLA_CLIENT_LOGO_URL ?? '',
  defaultLanguage: window.AIOLA_CLIENT_DEFAULT_LANGUAGE ?? 'en-us',
  fileStreaming: window.AIOLA_CLIENT_FILE_STREAMING ?? import.meta.env.VITE_FILE_STREAMING ?? false,
  maxFileSize: window.AIOLA_CLIENT_MAX_FILE_SIZE ?? 15,
  mixpanelToken: import.meta.env.MIXPANEL_TOKEN ?? '945b3da8a460da255fed89435686d53b',
  onGoingExecutionTimeLimit: window.AIOLA_ONGOING_EXECUTION_TIME_LIMIT ?? '86400000', // 1 DAY in ms
  biEventsBatchLimit: window.AIOLA_CLIENT_BI_EVENTS_BATCH_LIMIT ?? 300,
  pendingActionBatchLimit: window.AIOLA_CLIENT_PENDING_ACTION_BATCH_LIMIT ?? 100,
  baseUrl: import.meta.env.VITE_BASE_URL ?? '/',
  coralogixApiKey: window.AIOLA_CLIENT_RUM_PUBLIC_KEY ?? 'cxtp_CQjQ8skl7fNjxA22fPhKNnQiptWit7', // localhost key
  coralogixAppName: window.AIOLA_CLIENT_RUM_APPLICATION_NAME ?? 'flow-loop-frontend-localhost',
  coralogixDomain: window.AIOLA_CLIENT_RUM_DOMAIN ?? 'US2',
  backgroundRefreshTime: window.AIOLA_BACKGROUND_REFRESH_TIME ?? 1000 * 60 * 30, // 30 minutes
  dcCustomTitlesTypeIds: window.AIOLA_CLIENT_DC_CUSTOM_TITLES_TYPE_IDS ?? ['0b0aa82e-10df-44ed-9c10-1d0839186402'],
  getApiUrl: () => `${config.apiHost}${config.apiPath}`,
  getAuthApiUrl: () => `${config.authHost}${config.authPath}`,
} as const;
