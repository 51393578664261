import { MantineThemeOverride, rem } from '@mantine/core';

export const fontTheme: Pick<MantineThemeOverride, 'fontFamily' | 'fontFamilyMonospace' | 'fontSizes' | 'headings'> = {
  fontFamily: 'Noto',
  fontSizes: {
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(20),
    xl: rem(24),
  },
  headings: {
    fontWeight: '500',
    sizes: {
      h1: { fontSize: rem(48) },
      h2: { fontSize: rem(40) },
      h3: { fontSize: rem(32) },
      h4: { fontSize: rem(25) },
      h5: { fontSize: rem(20) },
      h6: { fontSize: rem(16) },
    },
  },
};
