import { useRef } from 'react';
import { useContainerStore } from 'stores/container';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Stack, TextInput, Text } from '@mantine/core';
import { useDynamicContainers } from '../../InspectionPage.hooks';

export const testIds = {
  dcTitleModal: 'dc-title-modal',
  dcTitleModalHeader: 'dc-title-modal-header',
  dcTitleModalInput: 'dc-title-modal-input',
  dcTitleModalConfirm: 'dc-title-modal-confirm',
};

interface DynamicContainerTitleModalProps {
  executionId: string;
}

export const DynamicContainerTitleModal = ({ executionId }: DynamicContainerTitleModalProps) => {
  const titleInputRef = useRef<HTMLInputElement>(null);
  const { dcTitleTypeId, setDCTitleTypeId } = useContainerStore(['dcTitleTypeId', 'setDCTitleTypeId']);
  const { addContainer } = useDynamicContainers(executionId);
  const { t } = useTranslation();

  const closeModal = () => {
    setDCTitleTypeId(undefined);
  };

  const createNewContainer = async () => {
    const title = titleInputRef.current?.value;
    if (dcTitleTypeId && title) {
      await addContainer(dcTitleTypeId, title);
      closeModal();
    }
  };

  return (
    <Modal
      title={
        <Text size='xl' data-testid={testIds.dcTitleModalHeader}>
          {t('inspection.dynamicContainersCustomTitle.modalTitle')}
        </Text>
      }
      centered
      padding='small'
      opened={!!dcTitleTypeId}
      onClose={closeModal}
      data-testid={testIds.dcTitleModal}
    >
      <Modal.Body>
        <Stack align='center'>
          <TextInput
            width='100%'
            ref={titleInputRef}
            placeholder={t('inspection.dynamicContainersCustomTitle.inputPlaceholder')}
            data-autofocus
            data-testid={testIds.dcTitleModalInput}
          />
          <Button onClick={createNewContainer} data-testid={testIds.dcTitleModalConfirm}>
            {t('common.confirm')}
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
