import { Flex, Transition } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { names, useSpy } from 'services/espionage';
import { PageWrapper } from 'components';
import { DEFAULT_TRANSITION_DURATION } from 'consts';
import { PageProvider } from 'providers/page.provider';
import { theme } from 'assets';
import { TimeCard } from './cards/TimeCard';
import { FlowCard } from './cards/FlowCard';
import { SummaryNavbar } from './components/SummaryNavbar/SummaryNavbar';
import { RatingCard, testIds as ratingTestIds } from './cards/RatingCard';

export const testIds = {
  pageContainer: 'summary-page-container',
  title: 'summary-page-title',
  flowInfo: 'summary-page-flow-info',
  executionInfo: 'summary-page-execution-info',
  submitButton: 'summary-page-submit-button',
  items: {
    userName: 'summary-page-user-name',
    flowName: 'summary-page-flow-name',
    totalTime: 'summary-page-total-time',
    startDate: 'summary-page-start-date',
    endDate: 'summary-page-end-date',
  },
  rating: ratingTestIds,
};

export const SummaryPage = () => {
  const [mounted, setMounted] = useState(false);
  const [{ rating, comment }, setRatingState] = useSetState({ rating: 0, comment: '' });
  const { spyPageview } = useSpy();

  const onChangeRating = (value?: number) => setRatingState((prev) => ({ rating: value === prev.rating ? 0 : value }));
  const onChangeComment = (value?: string) => setRatingState({ comment: value });

  useEffect(() => {
    spyPageview(names.SummaryPage.self);
    setMounted(true);
  }, []);

  return (
    <PageProvider theme={theme}>
      <PageWrapper px={0} data-testid={testIds.pageContainer}>
        <SummaryNavbar rating={rating} comment={comment} />
        <Flex direction='column' className='grow' gap='tn' px='sm'>
          <Transition transition='slide-down' mounted={mounted}>
            {(styles) => <FlowCard style={{ ...styles, transitionDelay: `${DEFAULT_TRANSITION_DURATION}ms` }} />}
          </Transition>
          <Transition transition='slide-down' mounted={mounted}>
            {(styles) => <TimeCard style={{ ...styles, transitionDelay: `${DEFAULT_TRANSITION_DURATION * 2}ms` }} />}
          </Transition>
          <Transition transition='slide-down' mounted={mounted}>
            {(styles) => (
              <RatingCard
                style={{ ...styles, transitionDelay: `${DEFAULT_TRANSITION_DURATION * 3}ms` }}
                value={rating}
                comment={comment}
                onChangeValue={onChangeRating}
                onChangeComment={onChangeComment}
              />
            )}
          </Transition>
        </Flex>
      </PageWrapper>
    </PageProvider>
  );
};
