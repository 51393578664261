import { Card, Flex, ScrollArea, Tabs } from '@mantine/core';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ExecutionRouteParams } from 'routes/routes.config';
import { names, useSpy } from 'services/espionage';
import { EmptyState, PageWrapper } from 'components';
import { ContainerItemSummary } from 'pages';
import { MARGIN_TO_AVOID_IPHONE_BOTTOM_BAR_THING } from 'consts';
import { useTranslation } from 'react-i18next';
import { PageProvider } from 'providers/page.provider';
import { theme } from 'assets';
import { useContainersByStep, useReviewSteps } from './ReviewPage.hooks';
import { ReviewNavbar } from './components/ReviewNavbar/ReviewNavbar';

export const testIds = {
  pageContainer: 'review-page-container',
  tabs: 'review-page-tabs',
  finishInspection: 'review-page-finishInspection',
  getEmptyStateTestId: (tabId: string) => `review-page-empty-state-${tabId}`,
  tab: (tabId: string) => `review-page-step-${tabId}`,
};

export const ReviewPage = () => {
  const { t } = useTranslation();
  const { spyClick, spyPageview } = useSpy();
  const { executionId } = useParams() as ExecutionRouteParams;
  const reviewStepsContainerIds = useContainersByStep();
  const reviewSteps = useReviewSteps(reviewStepsContainerIds);

  useEffect(() => spyPageview(names.ReviewPage.self), []);

  return (
    <PageProvider theme={theme}>
      <PageWrapper px={0} data-testid={testIds.pageContainer}>
        <ReviewNavbar executionId={executionId} />
        <Tabs defaultValue={reviewSteps.at(0)?.stepTitle} mih={0} className='grow flex flex-col'>
          <Tabs.List grow data-testid={testIds.tabs}>
            {reviewSteps.map((reviewStep) => (
              <Tabs.Tab
                key={reviewStep.stepTitle}
                value={reviewStep.stepTitle}
                onClick={() => spyClick(names.ReviewPage.Tab, reviewStep.stepTitle)}
                data-testid={testIds.tab(reviewStep.stepTitle)}
              >
                {reviewStep.stepTitle}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {reviewSteps.map((reviewStep) => (
            <Tabs.Panel key={reviewStep.stepTitle} value={reviewStep.stepTitle} mih={0} className='grow'>
              <ScrollArea h='100%' px='sm'>
                <EmptyState
                  visible={!reviewStep.containerSummaries.length}
                  highlight={t('review.noContainers.highlightedPhrase', { adj: reviewStep.stepTitle })}
                  highlightColor='gray'
                  data-testid={testIds.getEmptyStateTestId(reviewStep.stepTitle)}
                >
                  {t('review.noContainers.phrase', { adj: reviewStep.stepTitle })}
                </EmptyState>
                <Flex direction='column' gap='sm' pt='sm' pb={MARGIN_TO_AVOID_IPHONE_BOTTOM_BAR_THING}>
                  {reviewStep.containerSummaries.map((summaryProps) => (
                    <Card key={summaryProps.container.title} className='shrink-0'>
                      <ContainerItemSummary {...summaryProps} />
                    </Card>
                  ))}
                </Flex>
              </ScrollArea>
            </Tabs.Panel>
          ))}
        </Tabs>
      </PageWrapper>
    </PageProvider>
  );
};
