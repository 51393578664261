import { useEffect, useState } from 'react';
import { Outlet, useBlocker } from 'react-router-dom';
import { names, useSpy } from 'services/espionage';
import { appStore, useAppStore, DeviceOrientation } from 'stores/app';
import {
  useIsAppInBackground,
  useCacheSync,
  useOrientationListener,
  usePreventNavigationSwipes,
  useSyncPendingActions,
  useConnectionChecker,
  useExecutionErrorModal,
  useSyncBiEvents,
} from 'hooks';
import { getDeviceOrientation } from 'utils';

export const testIds = {
  appLayout: 'app-layout',
};

export const AppLayout = () => {
  const { spyMount } = useSpy();
  const [deviceOrientation, setDeviceOrientation] = useState<DeviceOrientation>(getDeviceOrientation());
  const { registerHermesHandlers, createNotificationSocket, setNavigationLock } = useAppStore([
    'registerHermesHandlers',
    'createNotificationSocket',
    'setNavigationLock',
  ]);

  useConnectionChecker();
  useCacheSync();
  useSyncPendingActions();
  useSyncBiEvents();
  useIsAppInBackground();
  useOrientationListener();
  usePreventNavigationSwipes();
  useExecutionErrorModal();

  useBlocker(() => {
    const { canNavigate } = appStore.getState();
    if (canNavigate) setNavigationLock(true);
    return !canNavigate;
  });

  useEffect(() => {
    createNotificationSocket();
    registerHermesHandlers();
    const handleResize = () => setDeviceOrientation(getDeviceOrientation());
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
  }, []);

  useEffect(() => spyMount(names.Screen.PageOrientation, deviceOrientation), [deviceOrientation]);

  return <Outlet />;
};
