import { DEFAULT_THEME, DefaultMantineColor, MantineColorsTuple, MantineThemeOverride } from '@mantine/core';

type ExtendedCustomColors = 'amber' | 'emerald' | 'red' | 'cool' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}

export const colorTheme: Pick<MantineThemeOverride, 'colors' | 'primaryColor' | 'primaryShade' | 'black'> = {
  primaryColor: 'emerald',
  colors: {
    amber: [
      '#fffbeb',
      '#fef3c7',
      '#fde68a',
      '#fcd34d',
      '#fbbf24',
      '#f59e0b',
      '#d97706',
      '#b45309',
      '#92400e',
      '#78350f',
    ],
    emerald: [
      '#ecfdf5',
      '#d1fae5',
      '#a7f3d0',
      '#6ee7b7',
      '#34d399',
      '#10b981',
      '#059669',
      '#047857',
      '#065f46',
      '#064e3b',
    ],
    red: ['#fef2f2', '#fee2e2', '#fecaca', '#fca5a5', '#f87171', '#ef4444', '#dc2626', '#b91c1c', '#991b1b', '#7f1d1d'],
    cool: [
      '#f9fafb',
      '#f3f4f6',
      '#e5e7eb',
      '#d1d5db',
      '#9ca3af',
      '#6b7280',
      '#4b5563',
      '#374151',
      '#1f2937',
      '#111827',
    ],
  },
  primaryShade: 6,
  black: DEFAULT_THEME.colors.gray[7],
};
