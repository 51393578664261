import { Flex, Image } from '@mantine/core';
import { CurrentUserAvatar } from 'components';
import { config } from 'services/config';

export const testIds = {
  portraitNavbar: 'portrait-navbar',
  aiolaLogo: 'navbar-aiola-logo',
  clientLogo: 'navbar-client-logo',
};

export const PortraitNavbar = () => (
  <Flex w='100%' h={60} align='center' justify='space-between' data-testid={testIds.portraitNavbar}>
    <Image src={`${config.clientLogoUrl}`} fallbackSrc='/logo.svg' h={32} w={64} data-testid={testIds.clientLogo} />
    <CurrentUserAvatar />
  </Flex>
);
