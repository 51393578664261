import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { Box, Button, Flex, Highlight, ScrollArea } from '@mantine/core';
import { ReportedMetadataField } from '@flow/flow-backend-types';
import { names, useSpy } from 'services/espionage';
import { FlowRouteParams, ROUTES } from 'routes/routes.config';
import { useFlowStore } from 'stores/flow';
import { MetaType, MetaValues, useFlowMeta } from 'stores/inspectionMeta';
import { PageWrapper } from 'components';
import { toaster } from 'services/toaster';
import { IconChevronRight } from '@tabler/icons-react';
import { MARGIN_TO_AVOID_IPHONE_BOTTOM_BAR_THING } from 'consts';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { ActionNavbar } from 'layouts';
import { PageProvider } from 'providers/page.provider';
import { theme } from 'assets';
import { RenderMetaField } from './RenderMetaField/RenderMetaField';
import { getInitialValues, getValidationSchema, transformValues } from './InspectionMetaPages.utils';
import classes from './InspectionMetaPages.module.css';

export const testIds = {
  pageContainer: 'pre-inspection-page-container',
  submit: 'pre-inspection-page-submit',
  form: 'pre-inspection-page-form',
};

const META_TYPE = MetaType.PRE;
const ERROR_TOAST_ID = 'pre-inspection-page-error-toast';

export const PreInspectionPage = () => {
  const { t } = useTranslation();
  const { flowId } = useParams() as FlowRouteParams;
  const navigate = useForceNavigate();
  const [awaitingExecution, setAwaitingExecution] = useState(false);
  const { spyClick, spyPageview } = useSpy();
  const [flow, startExecution] = useFlowStore((state) => [state.flows[flowId], state.startExecution]);
  const { metaFields, preValues, uniqueIdentifierFieldId } = useFlowMeta(META_TYPE, flowId);
  const uniqueIdentifierField = useMemo(
    () => metaFields.find(({ id }) => id === uniqueIdentifierFieldId),
    [metaFields, uniqueIdentifierFieldId],
  );

  const form = useForm({
    initialValues: getInitialValues(metaFields),
    validate: (values) => getValidationSchema(values, metaFields),
    transformValues: (values) => transformValues(values, metaFields),
  });

  useEffect(() => {
    if (!flow) navigate(ROUTES.FLOWS);
    spyPageview(names.MetaPage.self);
  }, [flowId]);

  const toastDuplicatedUniqueIdError = (duplicatedValue: string) => {
    const uniqueIdFieldName = uniqueIdentifierField?.title;
    toaster.error({
      id: ERROR_TOAST_ID,
      title: t('meta.pre.errors.duplicateUniqueId.alertTitle', { name: uniqueIdFieldName }),
      autoClose: false,
      message: (
        <Highlight
          size='md'
          highlight={`'${duplicatedValue}'`}
          highlightStyles={{
            fontWeight: 600,
            backgroundColor: 'unset',
          }}
        >
          {t('meta.pre.errors.duplicateUniqueId.alertMessage', { value: `'${duplicatedValue}'` })}
        </Highlight>
      ),
    });
  };

  const sendSubmitBiEvent = (values: ReportedMetadataField[]) => {
    const metaValues = values.reduce((acc: MetaValues, { id: valueId, value }) => {
      const fieldName = metaFields.find(({ id }) => id === valueId)?.title;
      if (fieldName) acc[fieldName] = value;
      return acc;
    }, {});
    spyClick(names.MetaPage.Submit, metaValues);
  };

  const onSubmit = async (values: Record<string, any>) => {
    const uniqueIdentifierValue = values[uniqueIdentifierFieldId];
    const metaValues = metaFields.map<ReportedMetadataField>(({ id, required, order, exposed }) => ({
      id,
      value: values[id],
      required: !!required,
      order,
      exposed,
    }));
    sendSubmitBiEvent(metaValues);
    toaster.dispose(ERROR_TOAST_ID);
    if (!form.isValid) return;

    const execution = await startExecution({
      flowId,
      version: flow.activeVersion,
      preInspectionMetadata: metaValues,
      uniqueIdentifier: uniqueIdentifierValue,
    });

    if (execution) {
      navigate(ROUTES.INSPECTION(execution.id));
    } else {
      form.setFieldError(uniqueIdentifierFieldId, true);
      toastDuplicatedUniqueIdError(uniqueIdentifierValue);
    }
    setAwaitingExecution(false);
  };

  const onBack = () => {
    spyClick(names.MetaPage.Back);
    navigate(ROUTES.FLOWS);
  };

  return (
    <PageProvider theme={theme}>
      <PageWrapper px={0} data-testid={testIds.pageContainer}>
        <Box className='shadow-md' mb={20}>
          <ActionNavbar title={flow?.name} onBack={onBack} />
        </Box>
        <ScrollArea px='sm' className={classes.preInspectionScrollArea}>
          <Box component='form' h='100%' onSubmit={form.onSubmit(onSubmit)} data-testid={testIds.form}>
            <Flex direction='column' gap={16} mih='100%' pb={MARGIN_TO_AVOID_IPHONE_BOTTOM_BAR_THING}>
              <Flex direction='column' className='grow' gap={16}>
                {metaFields.map((field) => (
                  <RenderMetaField
                    key={field.id}
                    field={{
                      ...field,
                      title:
                        field.id === uniqueIdentifierFieldId
                          ? `${field.title} (${t('inspection.uniqueIdentifierLabel')})`
                          : field.title,
                    }}
                    defaultValue={preValues[field.id] ?? field.defaultValue}
                    {...form.getInputProps(field.id)}
                  />
                ))}
              </Flex>
              <Button
                type='submit'
                size='lg'
                className='shrink-0'
                rightSection={<IconChevronRight />}
                loading={awaitingExecution}
                data-testid={testIds.submit}
              >
                {t('meta.pre.submit')}
              </Button>
            </Flex>
          </Box>
        </ScrollArea>
      </PageWrapper>
    </PageProvider>
  );
};
