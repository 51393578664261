import { Avatar, AvatarProps, ElementProps } from '@mantine/core';
import { forwardRef, useMemo } from 'react';
import { computerUserColor, getInitials } from '@aiola/frontend';
import { User } from '@flow/flow-backend-types';

interface UserAvatarProps extends AvatarProps, ElementProps<'div', keyof AvatarProps> {
  user?: User;
}

export const testIds = {
  getAvatarTestId: (id: string) => `user-avatar-${id}`,
  fallback: 'user-avatar-fallback',
};

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(({ user, ...props }, ref) => {
  const color = useMemo(() => user && computerUserColor(user), [user]);

  return user ? (
    <Avatar ref={ref} color={color} data-testid={testIds.getAvatarTestId(user.userId)} {...props}>
      {getInitials(user)}
    </Avatar>
  ) : (
    <Avatar ref={ref} data-testid={testIds.fallback} {...props} />
  );
});
