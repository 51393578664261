// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';

import { PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/i18n';

export const AppProviders = ({ children }: PropsWithChildren) => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
);
